import React, { Component } from 'react'
import ContactForm from '../../components/forms/ContactForm'
import LayoutStyleGuide from '../../components/layouts/LayoutStyleGuide'
import FormsElementsSG from '../../components/forms/FormsElementsSG'

export class styleguide extends Component {
	render() {
		return (
			<LayoutStyleGuide>
				<div className="sg-topic form-elements">
					<h2 className="topic-title">Topic: Forms</h2>
					<ContactForm />
					<FormsElementsSG />
				</div>
			</LayoutStyleGuide>
		)
	}
}

export default styleguide
