import React, { Component } from 'react'

export class LayoutStyleGuide extends Component {
	render() {
		return (
			<div className="page-container styleguide">
				<div className="sg-main-title-container">
					<h1 className="sg-main-title">Style Guide</h1>
				</div>
				<main>{this.props.children}</main>
			</div>
		)
	}
}

export default LayoutStyleGuide
